/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
/* You can add global styles to this file, and also import other style files */
@import './theme/variables';
@import './theme/helper-classes';
@import './theme/material';
@import './theme/main';
@import './theme/disco-variables.scss';


@font-face {
  font-family: "DejaVuSans";
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVuSans";
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}

.k-grid {
  font-family: "DejaVu Sans", "Arial", sans-serif;
}

@font-face {
  font-family: "FontAwesome";
  src: url("../assets/font/fontawesome-webfont.ttf") format("truetype"),
    url("../assets/font/fontawesome-webfont.woff2") format("woff2"),
    url("../assets/font/fontawesome-webfont.woff") format("woff"),
    url("../assets/font/fontawesome-webfont.eot"),
    url("../assets/font/fontawesome-webfont.svg") format("svg");
  font-size: 18px;
  font-weight: bold;
}

:root .light {
  --buttons-color: white;
  --horizontal-facet-button: #1580b299;
}

.auth-account.mat-option {
  overflow: unset !important;
  height: unset !important;
  min-height: 48px !important;

  .mat-option-text {
    height: 100%;
    display: flex !important;
    overflow: unset !important;
    justify-content: flex-start;

    .accuont-admins {
      font-size: 12px;
      line-height: 14px;
      white-space: normal;
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-left: 4px;
    }

  }
}

.custom-snackbar {
  color: var(--main-color);
  background: var(--dark-500);
}

.notifications-menu {
  max-height: 380px !important;
  min-width: 285px !important;
}
// .success-style {
//   background-color: #61d761 !important;
// }

// .error-style {
//   background-color:#eb2121 !important;
// }

// .warning-style {
//   background-color: #ebbb1d !important;
// }



fuse-vertical-navigation > div > img {

  width: 800px !important
}

// body  {
//     display: flex;
//     flex: 1 1 auto;
//     width: 100%;
//     max-width: 100%;
//     min-width: 0;

//     /* Base styles for individual layouts */
//     > * {
//         position: relative;
//         display: flex;
//         flex: 1 1 auto;
//         width: 100%;
//         height: 100% !important;
//     }

//     /* Base styles for components that load as a route */
//     router-outlet {
//         + * {
//             position: relative;
//             display: flex;
//              flex: 1 1 auto;
//              width: 100%;
//         }
//     }
// }
